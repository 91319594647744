@import '../../../styles/variables';

.new-product-sort-filter {
  .filter-wrapper {
    gap: calc(var(--spacing-unit) * 2);
  }
  .subcategory-filter-bar-mobile {
    display: none;
  }
  .swiper-slide {
    width: auto !important;
  }
  .left-side {
    flex: 1;
    min-width: 0;
    .filter-button {
      padding: 8px 23px;
      border: 2px solid var(--primary);
      height: 44px;
    }
    .filter-button:disabled {
      cursor: not-allowed;
      background-color: var(--disabled);
      opacity: 0.8;
      color: var(--secondary);
      pointer-events: none;
    }
    .reverse-column-flex {
      display: flex;
      flex-direction: column-reverse;
    }
    .subcategory-filter-bar-dt {
      flex: 1;
      min-width: 0 !important; // prevent flex-grow to take up more space than available
    }
  }
  .right-side {
    width: 356px;
    .view-button {
      background-color: var(--white);
      padding: 17px 8px;
      height: 56px;
      width: 56px;
      border-radius: 8px;
      border: 2px solid var(--secondary-primary);
      &.selected {
        border: 2px solid var(--primary);
      }
    }
  }
  .filter-chip {
    height: 33px;
    border: 2px solid var(--primary);
    background-color: transparent;
    border-radius: 4px;
    padding: 8px 16px;
  }
  @include media-breakpoint-down(tablet) {
    &.psf-loader {
      .reverse-column-flex > .loader {
        height: 56px !important;
        width: 72px !important;
      }
    }
    .filter-wrapper {
      gap: 24px;
    }

    .left-side {
      .filter-button {
        height: 56px;
        width: 72px;
        padding: 8px 12px;
        .filter-label {
          display: none;
        }
      }
    }
    .right-side {
      width: 208px;
      .view-button-group {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &.psf-loader {
      .reverse-column-flex > .loader {
        width: 100px !important;
      }
    }
    gap: 24px;
    display: flex;
    flex-direction: column;
    .filter-wrapper {
      gap: 12px;
    }
    .subcategory-filter-bar-dt {
      display: none;
    }
    .subcategory-filter-bar-mobile {
      display: revert;
    }
    .left-side {
      .filter-button {
        width: unset;
        .selected-filter-count {
          display: none;
        }
        .filter-label {
          display: revert;
        }
      }
    }
  }
}

.filter-sidebar {
  min-width: 375px;
  max-width: 495px;
  margin-left: 32px;
  width: 100%;
  padding-bottom: 154px;
  .p-sidebar-header {
    padding: 24px 48px;
    border-bottom: 1px solid var(--secondary-primary);
    .header {
      width: 100%;
      .filter-close-icon {
        border: none;
        padding-right: 0;
      }
    }
  }
  .p-sidebar-content {
    position: relative;
  }
}
