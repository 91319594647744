@import '../../styles/variables';

.ecom-product-grid-wrapper {
  .ecom-product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: var(--spacing-unit);
    grid-row-gap: var(--spacing-unit);
    .product-tile-container {
      min-width: 240px;
      width: unset;
    }
    @media (max-width: 1394px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1139px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .ecom-product-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 24px;
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }
  }
  .ecom-product-text-block-wrapper {
    width: 100%;
    margin-top: calc(var(--spacing-unit) * 2);
    margin-bottom: calc(var(--spacing-unit) * 2);
    .text-block {
      border-radius: 16px;
      border: 2px solid var(--secondary-primary);
    }
  }
  .ecom-grid-wrapper {
    width: 100%;
  }
  .ecom-slider {
    @extend .potency-slider;
  }
}

.potency-slider {
  width: calc(100% - 35px);
  margin: 10px 0 var(--spacing-unit) 10px;
  .p-slider-range {
    background: var(--primary);
  }
  .p-slider-handle {
    height: 22px;
    width: 22px;
    margin-top: -12px;
    background: var(--white);
    border: 2px solid var(--primary);
    &:hover {
      background: var(--white);
    }
    &:focus {
      box-shadow: none;
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 10px 0 var(--spacing-unit) var(--spacing-unit);
  }
}
