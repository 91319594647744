.specials-page-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: var(--spacing-unit);
  grid-row-gap: var(--spacing-unit);
  @media (max-width: 1394px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .specials-page-tile {
    border: 2px solid var(--secondary-primary);
    border-radius: 16px;
    position: relative;
    padding-bottom: calc(44px + 16px);
    .specials-image,
    .product-image {
      position: relative;
      height: 125px;
    }
    .product-image {
      flex-grow: 1;
      flex-basis: 0;
    }
    .st-btn-container {
      position: absolute;
      bottom: 16px;
      width: calc(100% - 32px);
    }
  }
}
