@import '../../styles/variables';

.specials-banner {
  position: relative;
  .ib-container {
    height: 60px;
    position: relative;
    padding: 16px;
    background: var(--tertiary-light);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    transition: all 0.4s cubic-bezier(0, 1, 0, 1);
    &.border-radius {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      transition: all 1s ease-in-out;
    }

    .ib-contents {
      .tag-icon {
        height: 24px;
        line-height: unset;
      }
      .ib-button {
        padding-inline: 5px;
        border: 1px solid var(--primary);
      }
      .ib-content {
        p {
          margin-bottom: 0;
        }
      }
    }
    .arrow {
      cursor: pointer;
      position: absolute;
      border: none;
      padding: none;
      right: 16px;
      height: 24px;
      > svg > path {
        stroke: var(--primary);
      }
      &.up {
        transform: rotate(-180deg);
      }
    }
    @include media-breakpoint-down(sm) {
      height: 52px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      &.border-radius {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
  .specials-dropdown {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    overflow: hidden;
    &.active {
      max-height: 500px;
      transition: max-height 1s ease-in-out;
    }
    position: absolute;
    background: var(--tertiary-light);
    width: 100%;
    z-index: 100;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    @include media-breakpoint-down(sm) {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .content {
      list-style: none;
      margin: 0;
    }
  }
}
