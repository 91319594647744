@import '../styles/variables';

.pagination {
  height: 56px;
  button:not(:disabled) {
    cursor: pointer;
    &:hover {
      background-color: var(--secondary-primary);
    }
  }
  button,
  .current-page {
    padding: var(--spacing-unit) 20px;
    height: 100%;
    background-color: var(--white);
    color: var(--primary);
    border: 1px solid var(--secondary-primary);
    &:disabled {
      color: var(--black);
      background-color: var(--disabled);
    }
  }
  button:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  button:last-of-type {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
