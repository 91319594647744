@import '../../styles/variables';

.storefront-banners-carousel {
  overflow: hidden;
  padding-right: calc(var(--spacing-unit) * 2);
  .carousel-el {
    img {
      border-radius: 16px;
    }
  }
  a {
    display: inline-block;
    width: 100%;
    height: auto;
  }
  @include media-breakpoint-down(tablet) {
    padding: 0 calc(var(--spacing-unit) * 2);
  }
  @include media-breakpoint-down(sm) {
    padding: 0 var(--spacing-unit);
  }
}

.storefront-banner-dots.thumbs {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  .sm-dot {
    padding: 0;
    border: 1px solid var(--secondary-primary);
    width: 8px;
    height: 8px;
    background-color: var(--secondary-primary);
    border-color: var(--secondary-primary);
    border-radius: 100%;
    margin: var(--spacing-unit) 8px 0 8px;
    &.active {
      border: 1px solid var(--primary);
      background-color: var(--primary);
      border-color: var(--primary);
    }
  }
}
