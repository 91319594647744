@import '../../styles/variables';

.ecom-product-grid-wrapper {
  @media (max-width: 1023px) {
    .dt-product-filter-wrapper {
      display: none;
    }
  }
  .ecom-product-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: var(--spacing-unit);
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }
  }
  .ecom-product-text-block-wrapper {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    .text-block {
      background-color: var(--white);
    }
  }
  .ecom-grid-wrapper {
    width: 100%;
  }
  .ecom-slider {
    @extend .potency-slider;
  }
  .filter-subtitle {
    width: 300px;
    @media (max-width: 1024px) {
      margin-left: 0px;
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.potency-slider {
  width: calc(100% - 35px);
  margin: 10px 0 var(--spacing-unit) 10px;
  .p-slider-range {
    background: var(--primary);
  }
  .p-slider-handle {
    height: 22px;
    width: 22px;
    margin-top: -12px;
    background: var(--white);
    border: 2px solid var(--primary);
    &:hover {
      background: var(--white);
    }
    &:focus {
      box-shadow: none;
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 10px 0 var(--spacing-unit) var(--spacing-unit);
  }
}
