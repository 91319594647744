@import '../../../../FormBuilder/components/form';
@import '../../../ecom-product-grid.scss';

.filter-accordion-wrapper {
  .curaleaf-accordion.secondary {
    .curaleaf-accordion-header .p-accordion-header-link {
      padding: 24px 48px;
    }
    .curaleaf-accordion-content .p-accordion-content {
      padding: 0 48px 24px;
    }
  }
  .filter-content {
    gap: 24px;
    .filter-item-checkbox {
      @extend .checkbox-container-curaleaf;
      .p-checkbox {
        margin-top: 0;
      }
    }
    .ecom-slider-container {
      width: 100%;
      .ecom-slider {
        @extend .potency-slider;
      }
    }
  }
}
