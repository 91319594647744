@import '../../../styles/variables';

.pdp-image-carousel {
  position: relative;
  height: 100%;
  .strain-tag {
    position: absolute;
    left: -8px;
    top: 7px;
    z-index: 1;
  }
  .deal {
    position: absolute;
    left: 6px;
    top: 14px;
    z-index: 2;
  }
  .carousel-wrapper {
    flex-grow: 1;
  }
  .carousel {
    height: 100%;
    .carousel-el {
      padding: 0 10px;
      > div {
        background-color: var(--white);
        max-height: 650px;
        height: 100%;
        @include media-breakpoint-down(sm) {
          height: 398px;
        }
      }
      .product-image-el {
        position: relative;
        height: 100%;
        .pdp-image-carousel .strain-tag {
          left: -6px;
        }
        > img {
          border-radius: 16px;
          border: 2px solid rgba(89, 131, 141, 0.1);
        }
      }
    }
  }
  .storefront-banner-dots.thumbs {
    margin-top: 0;
    .sm-dot {
      margin: 0 8px;
    }
  }
  @include media-breakpoint-down(tablet) {
    flex-direction: column-reverse;
  }
  @include media-breakpoint-down(sm) {
    gap: 16px;
  }
}
