@import '../../../styles/variables';

.brand-accordion-content {
  .brand-description {
    .image-col {
      width: 100px;
      height: 100px;
      position: relative;
      display: block;
      overflow: hidden;
      img {
        object-fit: contain;
      }
    }
    .description-col {
      flex: 1;
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
}
