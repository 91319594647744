@import '../../../styles/variables';

.overview-card-container {
  min-width: 250px;
  max-width: 250px;
  width: 100%;
  margin-right: 64px;
  margin-left: calc(var(--spacing-unit) * 2);
  .title {
    margin-bottom: 12px;
  }
  .hours {
    position: relative;
    margin-bottom: 12px;
    svg:first-child {
      margin-right: 6px;
    }
  }
  @include media-breakpoint-down(tablet) {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    align-items: center;
    margin: 0 0 calc(var(--spacing-unit) * 2) 0;
    padding: 0 calc(var(--spacing-unit) * 2);
    .title,
    .hours {
      margin-bottom: 0;
    }
  }
  .hours-content {
    border-radius: 8px;
    background: var(--secondary-light);
    ul {
      margin: 0;
      padding: var(--spacing-unit);
      li:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
  .map {
    display: inline-block;
    height: 120px;
    background-color: var(--tertiary-dark);
    overflow: hidden;
    img {
      object-position: center;
      width: 250px;
      height: 120px;
      object-fit: none;
    }
  }
  .cta-button > a {
    width: 100%;
  }

  .hours-popup {
    z-index: 100;
    position: absolute;
    right: 0;
    top: 36px;
    min-width: 230px;
    .tail-container {
      position: relative;
      .popup-tail {
        border-right: 1px solid var(--secondary-light);
        border-top: 1px solid var(--secondary-light);
        background-color: var(--secondary-light);
        height: 10px;
        width: 10px;
        position: absolute;
        transform: rotateZ(-45deg);
        bottom: -5px;
        right: 5px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 0 var(--spacing-unit);
  }
}
