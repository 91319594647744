@import '../../styles/variables';
@import '../FormBuilder/components/form.scss';

.product-description {
  flex-direction: row-reverse;
  .description p {
    font-size: inherit;
    line-height: inherit;
  }
  .left-col {
    width: 57%;
  }
  .right-col {
    width: 43%;
    .variant-dropdown {
      @extend .dropdown-curaleaf;
      > span {
        margin-top: 21px;
        > label[for='option'] {
          font-size: 16px !important;
        }
      }
    }
    .single-variant {
      height: 56px;
      background-color: var(--secondary-light);
      padding: 16px 12px;
      border-radius: 12px;
    }
    .cart-button-col {
      flex-grow: 1;
      .cta-button-tertiary {
        border: 4px solid var(--primary);
      }
    }
  }
  @include media-breakpoint-down(tablet) {
    .left-col {
      width: 45%;
    }
    .right-col {
      width: 55%;
    }
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    padding-bottom: 0;
    .left-col,
    .right-col {
      width: 100%;
    }
  }
}

.pdp-divider {
  height: 1px;
  border-top: 1px solid rgba(89, 131, 141, 0.1);
}

.pdp-cart-buttons {
  padding: 0 var(--spacing-unit);
  .quantity-select {
    margin-right: 15px;
  }
}

.pdp-lower {
  #specifications {
    scroll-margin-top: 100px;
  }
  .pdp-description {
    p {
      margin: 0;
    }
  }
  .accordion-content > div {
    padding: 24px 0 0 0;
  }
  .product-spec-list {
    list-style: none;
    padding: 0;
    margin: 0;
    > li > span {
      width: 50%;
    }
    > li:not(:first-child) {
      margin-top: 16px;
    }
  }
  @include media-breakpoint-down(sm) {
    gap: 16px;
    .product-spec-list {
      > li > span:last-child {
        text-align: right;
      }
    }
  }
}

#moodi-day-widget {
  .moodi-day-video-review-header {
    margin-bottom: 0;
    h2 {
      color: var(--primary);
      .moodi-day-info-icon {
        margin: 5px 0 0 5px;
      }
    }
  }
  .slick-slider .slick-track {
    width: 100% !important;
    overflow-x: auto;
  }
  .slick-slider .slick-slide {
    min-width: 220px;
    width: 100% !important;
  }
  @include media-breakpoint-down(sm) {
    .moodi-day-video-review-module {
      padding: 24px var(--spacing-unit);
    }
  }
}
