@import '../../../styles/variables';

.page-title {
  line-height: 0.8;
  @include media-breakpoint-down(sm) {
    padding-top: var(--spacing-unit);
    line-height: 0.9;
  }
}

.product-sort-filter {
  padding-top: calc(var(--spacing-unit) * 2);
  position: relative;
  @include media-breakpoint-down(sm) {
    padding-top: var(--spacing-unit);
  }
  .button-group > a {
    width: 100%;
    padding: 14px 20px;
    border: 1px solid var(--light-neutral-grey);
  }
}

.product-sort-filter-desktop {
  display: flex;
  padding: 0 30px;
  background-color: var(--ecom);
  justify-content: flex-end;
  .sort-by,
  .product-view {
    margin-left: 15px;
    > div {
      margin: 0;
    }
  }
  .sort-by .select-wrapper {
    margin-bottom: 0;
    .p-dropdown {
      border-radius: 4px;
      border-color: var(--secondary-light);
      height: 40px;
      .p-dropdown-label {
        color: var(--dark-grey);
        font-size: 14px;
      }
      .p-inputtext {
        padding: 8px 12px;
      }
      .p-dropdown-trigger {
        width: 40px;
        color: var(--dark-grey);
      }
    }
  }
  .product-view {
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 40px;
      width: 44px;
      border: 2px solid var(--secondary-light);
      background-color: var(--white);
      padding: 0;
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      &.selected {
        border: 2px solid var(--primary);
      }
    }
  }
}

.old-mobile-filter-modal {
  .p-sidebar-bottom .p-sidebar {
    height: 85% !important;
  }
  .p-sidebar {
    .p-sidebar-header {
      display: none;
    }
    .p-sidebar-content {
      padding: 0 !important;
      .close-container {
        padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 2)
          var(--spacing-unit);
      }
      .filter-accordion {
        margin: 0;
      }
    }
  }
}
