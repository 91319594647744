@import '../../../styles/variables';
@import '../ecom-product-grid.scss';
@import '../../FormBuilder/components/form.scss';

.product-filter {
  width: 300px;
  .filter-accordion {
    padding: 30px;
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--light-gray);
    }
    .header {
      padding-bottom: 10px;
      .expand-btn {
        transition: all 0.3s;
        svg > path {
          fill: var(--dark-grey);
        }
      }
      &[data-menu-open='false'] {
        .expand-btn > svg {
          transform: rotateX(180deg);
        }
      }
    }
    .filters {
      overflow: auto;
      gap: var(--spacing-unit);
      width: 100%;
      max-height: 0;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
      &[data-visible='true'] {
        max-height: 300px;
        overflow-y: auto;
        transition: max-height 1s ease-in-out;
        @media (max-width: 1024px) {
          max-height: unset;
        }
      }
      &[data-loading='true'] {
        max-height: 300px;
      }
      .filter-item {
        @extend .checkbox-container-curaleaf;
        gap: 5px;
      }
      .mobile-filter-tag {
        gap: 8px;
        width: fit-content;
        text-decoration: none;
        border: 1px solid var(--primary);
        padding: 10px 16px;
        &[data-selected='true'] {
          color: var(--primary);
          font-family: var(--font-bold);
          &:hover {
            text-decoration: none;
          }
        }
      }
      .ecom-slider-container {
        width: 100%;
        .ecom-slider {
          @extend .potency-slider;
        }
      }
      section > div > div > .select-dropdown-container {
        width: 100%;
        .dropdown {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 1023px) {
    gap: 20px;
    width: 100%;
    padding: 0 15px 15px;
    .mobile-accordion-header {
      position: relative;
      h5 {
        width: 100%;
      }
      .filter-close-icon {
        background-color: transparent;
        border: none;
        position: absolute;
        right: 0;
        &:hover {
          svg > path {
            fill: var(--tertiary);
          }
        }
      }
    }
    .filter-accordion {
      padding: unset;
      border-bottom: none !important;
      .header {
        padding-bottom: 8px;
      }
      .filters {
        flex-wrap: wrap;
        gap: 8px;
        &[data-redirect='false'] {
          flex-direction: row;
        }
      }
    }
  }
}
.results-button {
  z-index: 3;
  position: sticky;
  bottom: 0;
  gap: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  .cart-button {
    height: unset;
    padding: 12px 18px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
  }
}
