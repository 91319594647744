.filter-modal-results-button {
  border-top: 1px solid var(--secondary-primary);
  background-color: var(--white);
  position: fixed;
  max-width: 495px;
  width: -webkit-fill-available;
  bottom: 0;
  a {
    &[data-disabled='true'] {
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    }
  }
}
