.product-details {
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
    }
  }
  .bullet {
    li:not(:last-child):after {
      margin: 0 5px;
      content: '\2022';
    }
  }
  .comma {
    li:not(:last-child):after {
      margin-right: 5px;
      content: '\002C';
    }
  }
  .more-link {
    scroll-margin-top: 250px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: var(--input-normal);
    }
  }
}
